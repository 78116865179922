import {$axios, GcResponse} from "../../../helper";
import {ServerClippngPay} from "../../../../lib/constants/server";

export type TPriceListFindParam = {
    token: string,
    type: number
}
// 套餐信息
export type IPriceListItem = {
    id: number,
    title: string,
    info: string,
    ico_img: string,
    original_price: number,
    real_price: number,
    days: number,
    points: number,
    stripe_id: number,
    download_num: number
}

/** 拿付费套餐
 * @see http://doc.bigbigwork.com/docs/hw/8029
 */
export default function fetchPriceListFind (
    params: TPriceListFindParam
) {
    return $axios<GcResponse<IPriceListItem[]>> ({
        url: `${ServerClippngPay}/price/list/find`,
        method: "GET",
        headers: {
          token: params.token
        },
        params: {
            type: params.type
        }
    })
}