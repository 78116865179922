<script lang="ts" setup>
import type { TUserInfo } from "global-common";
import {buyVip} from "@helpers/utils";
import { useStorePay } from "@stores/useStorePay";
import { storeToRefs } from "pinia";

defineOptions({
  name: `DropdownVip`
})
const props = defineProps<{
  // 用户信息
  userInfo: TUserInfo;
}>();
const { priceData } = storeToRefs(useStorePay());
const src2x = ref<string>(`${process.env.CDN_CP_PATH}/img/1/img-dropdown%402x.png`);
const src = ref<string>(`${process.env.CDN_CP_PATH}/img/1/img-dropdown.png`);
const srcset = computed(()=>{
  return `${src.value} 1x, ${src2x.value} 2x`
})
const handleVip = () => {
  buyVip ( { fromText:`导航下拉` })
}
// 显示的价格
const price = computed(()=>{
  const list = priceData?.value?.priceList || [];
  // 第一个非0的价格
  const firstPrice = list.find(item => item.real_price !== 0 );
  return firstPrice ;
})
const featureDownload = computed(()=>{
  const num = price?.value?.download_num;
  if ( num === -1 ) {
    return `Unlimited downloads`
  } else {
    return `${num} Downloads / day`
  }
})
const featurePoints = computed(()=>{
  return `For commercial purpose`;
})
</script>
<template>
  <div :class="[$style[`dropdown-vip`], `dropdown`]">
    <div :class="$style[`dropdown-vip-wrapper`]">
      <img :src="src" :srcset="srcset" :class="$style[`bg`]"/>
      <h3 :class="$style[`title`]">Personal</h3>
      <!-- 权益 -->
      <div :class="$style[`plan-bk__feature`]">
        <ol :class="$style[`plan-bk__feature__list`]">
          <li>
            <img :src="`${process.env.CDN_CP_PATH}/img/icon/icon-plan__feature.svg`"/>
            <span>{{ featureDownload }}</span>
          </li>
          <li>
            <img :src="`${process.env.CDN_CP_PATH}/img/icon/icon-plan__feature.svg`"/>
            <span>{{featurePoints}}</span>
          </li>
        </ol>
      </div>
      <button  :class="$style[`vip-btn`]" @click="handleVip">Buy Now</button>
    </div>
  </div>
</template>
<style lang="scss" module src="./dropdown-vip.scss"></style>