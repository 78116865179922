import { useRouter, useRoute } from "vue-router";
export default {
    name: `AutoLink`,
    props: {
        // 没有链接时的标签
        tag: {
            type: String,
            default: `div`
        },
        // 跳转链接（http|https开头跳转到外部链接，其他路由跳转)
        link: {
            type: String,
            default: ``
        },
        // 跳转方式（auto:根据link自动判断;newTab:新窗口打开; replace: 替换当前页面; self:当前页面打开 ）
        target: {
            type: String,
            default: `newTab`
        },
        // 检查是否登录，未登录时不跳转，而是触发login事件
        checkLogin: {
            type: Boolean,
            default: false
        },
        // 是否已登陆
        isLogin: {
            type: Boolean,
            default: false
        }
    },
    emits: [`login`, `click`],
    setup ( props, context ) {
        const { slots, attrs, emit, expose } = context;
        const route = useRoute();
        // 是否要弹出登录框
        const needLogin = computed(()=>{
            return props.checkLogin && !props.isLogin && route.meta.needLogin ;
        });
        const router = useRouter();
        if ( !props.link ) {
            return () => h (
                props.tag,
                {
                    ...attrs,
                    onClick: ( e ) => {
                        e.preventDefault();
                        emit ( `click`, e ) ;
                    }
                },
                slots.default() );
        } else {
            const isLink = props.link.match(/^(https?:)?\/\//);
            const target = isLink ? `_blank` : `_self`;
            return () => h (
                `a`,
                {
                    ...attrs,
                    href: props.link,
                    target: target,
                    onClick: ( e ) => {
                        e.preventDefault();
                        emit ( `click`, e ) ;
                        if ( needLogin.value ) {
                            emit ( `login`, { redirect: props.link })
                        } else {
                            if ( isLink || props.target !== `auto` ) {
                                if ( props.target === `replace` ) {
                                    window.location.replace( props.link) ;
                                } else if ( props.target === `self` ) {
                                    window.location.href = props.link;
                                } else {
                                    window.open ( props.link, `_blank` ) ;
                                }
                            } else {
                                // 路由解析
                                const locationRaw = router.resolve( props.link) ;
                                if ( props.target === `replace` ) {
                                    router.replace ( locationRaw ) ;
                                } else {
                                    router.push ( locationRaw ) ;
                                }
                            }
                        }
                    }
                },
                slots.default()
            )
        }
    }
}