<template>
  <a v-if="isLink" :target="target" :href="linkPath" :class="[$style.d_nav_item, activity? $style.activity : '']">
    <span :class="[$style.text]">{{ text }}</span>
  </a>
  <router-link v-else :to="routerPath" :class="[$style.d_nav_item, activity? $style.activity : '']">
    <span :class="[$style.text]">{{ text }}</span>
  </router-link>
</template>

<script lang="ts">
import {defineComponent, computed, toRefs} from "vue";

export default defineComponent({
  name: "DNavItem",
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    /* 路由跳转的地址 */
    routerPath: {
      type: String,
      required: false,
      default: '/'
    },
    newTab: {
      type: Boolean,
      required: false,
      default: false
    },
    /* 是否是a链接 */
    isLink: {
      type: Boolean,
      required: false,
      default: false
    },
    /* 链接地址 */
    linkPath: {
      type: String,
      required: false,
      default: ''
    },
    activity: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const {newTab} = toRefs(props)
    const target = computed(() => {
      return newTab.value? '_blank' : '_self'
    })
    return {
      target
    }
  }
})
</script>

<style module lang="scss">
.d_nav_item{
  display: inline-block;
  padding: 0 ;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  background-color: transparent;
  border-radius: 16px;
  color: var(--theme-black);
  @extend .font-medium;
  &:hover{
    color: var(--g-color-theme);
  }
  &.activity{
    color: var(--g-color-theme);
    @extend .font-bold
  }
  //@media screen and (max-width: 1440px){
  //  padding: 0 12px;
  //}
}
.text{
  font-size: 16px;
  //color: #FFFFFF;
  white-space: nowrap;
}
</style>
