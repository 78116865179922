<template>
  <div :class="[$style[`wrapper`], `dropdown`]">
    <div :class="$style[`user-info`]">
      <!-- 用户信息 -->
      <div :class="$style[`info-bk`]">
        <div :class="$style[`avatar`]">
          <gc-avatar :avatar="userInfo.avatar" :name="userInfo.username"></gc-avatar>
        </div>
        <div :class="$style[`info`]">
          <span :class="$style[`name`]">{{ userInfo.username }}</span>
          <span :class="$style[`id`]">User ID：{{ userInfo.userid }}</span>
        </div>
      </div>
      <!-- 功能列表 -->
      <div :class="$style[`actions`]">
        <ul :class="$style[`list`]">
          <li :class="[$style[`li`],$style[`profile`]]" @click="handleToUserCenter">
            <a href="/user/my-favorites" @click.prevent>
              <inline-svg :class="[$style.icon]" :src="iconSettings"/>
              <span>My Profile</span>
            </a>
          </li>
<!--          <li :class="[$style[`li`],$style[`help`]]" @click="handleToHelp">-->
<!--            <a href="javascript:;">-->
<!--              <inline-svg :class="[$style.icon]"  :src="iconHelp"/>-->
<!--              <span>Help Center</span>-->
<!--            </a>-->
<!--          </li>-->
          <li :class="[$style[`li`],$style[`logout`]]" @click="handleLogout">
            <a href="javascript:;">
              <inline-svg :class="[$style.icon]"  :src="iconLogout"/>
              <span>Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import iconSettings from "@images/icon_settings.svg";
import iconLogout from "@images/icon_logout.svg";
import iconHelp from "@images/icon_help.svg";
import { useStoreUserInfo } from "@stores/useStoreUserInfo";
import type { TUserInfo } from "global-common";

const props = defineProps<{
  // 用户信息
  userInfo: TUserInfo;
}>();

const { resetUserInfo } = useStoreUserInfo();

/* ***************** Methods ***************** */
const handleToHelp = () => {
  const url = `/help`;
  window.open(url, "_blank");
};
const handleToUserCenter = () => {
  const url = `/user/my-profile`;
  window.open(url, "_blank");
};
const handleLogout = () => {
  console.log("logout");
  resetUserInfo();
};
</script>
<style lang="scss" module src="./dropdown-user-info.scss"></style>
