<script lang="ts" setup>
const props = defineProps({
  avatar: {
    type: String,
    default: ``
  },
  name: {
    type: String,
    default: `U`
  },
  size: {
    type: String,
    default: `normal`
  }
})
const colors = ref<string[]>([
   `#D32F2F`, `#D81B60`, `#AB47BC`, `#7E57C2`, `#5C6BC0`,
  `#1976D2`, `#0277BD`, `#006064`, `#00796B`, `#2E7D32`,
  `#33691E`, `#8D6E63`, `#BF360C`, `#546E7A`
]);

const username = computed(()=>{
  if ( !props.name ) return `U`;
  const firstTwo = props.name.slice(0,2);
  if ( firstTwo.match(/^[a-zA-Z0-9]+$/)) {
    return firstTwo.toUpperCase() ;
  } else {
    return props.name[0];
  }
})

const avatarStyle = computed(()=>{
  if ( props.avatar ) return {
    backgroundImage: `url(${props.avatar})`
  }
  // 根据名字生成颜色，保证名字和颜色对应关系
  const index = username.value.charCodeAt(0) % colors.value.length;
  return {
    backgroundColor: colors.value[index]
  }
})

</script>
<template>
  <div :class="[$style.avatar, $style[props.size]]" :style="avatarStyle">
      <span v-if="!props.avatar" :class="$style[`name`]">{{username}}</span>
  </div>
</template>
<style lang="scss" module src="./avatar.scss"></style>