<template>
<div :class="[$style[`header`],$style[props.position]]">
  <div :class="$style[`main`]">
    <div :class="$style[`l`]">
      <h1 :class="$style.logo">
        <a :class="$style.d_log" href="/"></a>
      </h1>
      <div :class="[$style.nav_item_list]">
        <dNavItem
            :class="$style[`nav_item_li`]"
            text="Home"
            :activity="isActiveRoute([`index`])"
            :is-link="true"
            :link-path="`/`"
            title="Royalty free png images, vectors, backgrounds, templates, text effect"
        />
        <dNavItem
            :class="$style[`nav_item_li`]"
            text="PNG Images"
            title="PNG Images"
            :new-tab="true"
            :link-path="`/search`"
            :activity="isActiveRoute([`search`, `searchIndex`])"
            :is-link="true"
            @click.capture="navAnalysis('PNG Images')" />
<!--        <dNavItem :class="$style[`nav_item_li`]"-->
<!--                  text="PNG Generator"-->
<!--                  title="PNG Generator"-->
<!--                  :activity="false"-->
<!--                  :new-tab="true"-->
<!--                  :link-path="`#`"-->
<!--                  @click.capture="navAnalysis('PNG Generator')"-->
<!--                  :is-link="true"-->
<!--        />-->
<!--        <dNavItem-->
<!--            :class="[$style[`nav_item_li`]]"-->
<!--            :activity="false"-->
<!--            @click.capture="navAnalysis('Generate 3D')"-->
<!--            title="Generate 3D"-->
<!--            text="Generate 3D"-->
<!--            :new-tab="true"-->
<!--            :link-path="`#`"-->
<!--            :is-link="true" />-->
<!--        <dNavItem-->
<!--            :class="$style[`nav_item_li`]"-->
<!--            @click.capture="navAnalysis('Word Art')"-->
<!--            text="Word Art"-->
<!--            title="Word Art"-->
<!--            :new-tab="true"-->
<!--            :link-path="`#`"-->
<!--            :is-link="true" />-->
      </div>
    </div>
    <div :class="[$style.r, $style[`unlogin-btns`]]" v-show="userIsFinished">
      <dButton :class="[$style.sign_up, $style[`unlogin-btn`]]" :has-border="false" border-radius="4px" @click="signUp('Sign up')">
        <span :class="[$style.btn_text, 'font-medium']">Sign up</span>
        <!--        <div :class="$style.tips">注册即送3天VIP</div>-->
      </dButton>
      <dButton :class="[$style.sign_in,$style[`unlogin-btn`]]" border-radius="4px" @click="signUp('Log in')">
        <span :class="[$style.btn_text, 'font-medium']">Log in</span>
      </dButton>
    </div>
  </div>
</div>
</template>
<script lang="ts" setup>
import dButton from '@components/public/d-button/d-button.vue'
import dNavItem from '@components/public/d-nav-item/d-nav-item.vue'
import {showLoginModel} from "@helpers/ui";
import {useStoreUserInfo} from "@stores/useStoreUserInfo.ts";
import {storeToRefs} from "pinia";
import {useRoute} from "vue-router";
const storeUserInfo = useStoreUserInfo();
const { userIsFinished } = storeToRefs(storeUserInfo);

const props = defineProps({
  // 布局 （`relative` | `sticky` )
  position: {
    type: String,
    default: `sticky`
  }
})
const route = useRoute();
/* ********************** Methods ********************** */
// 导航分析
const navAnalysis = (type: string) => {
  console.log('navAnalysis', type)
}
// 注册
const signUp = async (type: string) => {
  navAnalysis(type)
  //showLoginModal()
  const res = await showLoginModel ();
  console.log ( `signUp`, res );
}
/* ********************** Computed ********************** */
const isActiveRoute = computed ( () => {
  return ( names: string[]) => {
    return names.includes( route.name as string );
  }
});
</script>
<style lang="scss" module src="../header-navigator.scss"></style>