import { defineStore } from "pinia";
import fetchPriceListFind, {IPriceListItem} from "global-common/apis/pay-clippng/price/list/find.ts";

// 价格列表
export const useStorePay = defineStore( `Pay`, () => {
    const priceData = reactive({
        // 加个列表
        priceList:[],
        isLoading: true,
        isPriceReady: false
    } as {
        priceList: IPriceListItem[],
        isLoading: boolean,
        isPriceReady: boolean
    })
    // 获取加个列表
    const FETCH_PLAN_LIST = async ( opts?:{token?:string, type?: number, force?:boolean}) => {
        try {
            const { token = ``, type= 1, force = false } = opts || {};
            if ( !force && priceData.isPriceReady ) return [null, priceData.priceList];
            priceData.isLoading = true;
            // 从服务器获取价格
            const [ err, res ] = await fetchPriceListFind ( {token, type });
            if ( err ) throw err;
            priceData.priceList = res.data;
            priceData.isLoading = false;
            priceData.isPriceReady = true;
            return [null, priceData.priceList]
        } catch ( e ) {
            return [e, priceData.priceList]
        }
    }
    return {
        FETCH_PLAN_LIST, priceData
    }
})