<template>
  <div :class="[$style[`header`], $style[props.position]]">
    <div :class="$style[`main`]">
      <div :class="$style[`l`]">
        <h1 :class="$style.logo">
          <a :class="$style.d_log" href="/"></a>
        </h1>
        <div :class="[$style.nav_item_list]">
          <dNavItem
              :class="$style[`nav_item_li`]"
              text="Home"
              :activity="isActiveRoute([`index`])"
              :is-link="true"
              :link-path="`/`"
              title="Royalty free png images, vectors, backgrounds, templates, text effect"
          />
          <dNavItem
              :class="$style[`nav_item_li`]"
              text="PNG Images"
              title="PNG Images"
              :new-tab="true"
              :link-path="`/search`"
              :activity="isActiveRoute([`search`, `searchIndex`])"
              :is-link="true"
              @click.capture="navAnalysis('PNG Images')" />
        </div>
      </div>
      <div :class="[$style.r, $style[`logined`]]" v-show="userIsFinished">
        <!-- 购买VIP -->
        <div :class="$style.plan" >
          <div :class="$style[`plan-bk`]" @click="handleVip"><i></i><span>Pricing</span></div>
          <dropdown-vip v-if="isUserReady && !isVip && priceData.isPriceReady" :user-info="userInfo"></dropdown-vip>
        </div>
        <div :class="$style[`user-bk`]">
          <div :class="$style[`user-avatar`]" @click.stop="handleToUserCenter">
            <gc-avatar :avatar="userInfo.avatar" :name="userInfo.username"></gc-avatar>
          </div>
          <dropdown-user-info :user-info="userInfo"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import dNavItem from '@components/public/d-nav-item/d-nav-item.vue';
import { useStoreUserInfo } from "@stores/useStoreUserInfo";
import { reactive } from "vue";
import DropdownUserInfo from "@components/dropdown-user-info/dropdown-user-info.vue";
import DropdownVip from "@components/dropdown-vip/dropdown-vip.vue";
import { useRoute } from "vue-router";
import {buyVip} from "@helpers/utils";
import { useStorePay } from "@stores/useStorePay";
import { waitLogin } from "global-common";

const props = defineProps({
  // 布局 （`relative` | `sticky` )
  position: {
    type: String,
    default: `sticky`
  }
})
/** ********************* Data ********************* */
const data = reactive({

});
const storeUserInfo = useStoreUserInfo();
const {userInfo, isUserReady, userIsFinished, isVip } = storeToRefs(storeUserInfo);
const route = useRoute();
const { getToken } = storeUserInfo;
const { FETCH_PLAN_LIST, priceData } = useStorePay();
/* ********************** Hooks ********************** */
onBeforeMount(async () => {
  const token = getToken();
  await waitLogin();
  if ( !isVip.value ) {
    await FETCH_PLAN_LIST( { token, type:1 });
  }
});
/* ********************** Methods ********************** */
// 导航分析
const navAnalysis = (type: string) => {
  console.log('navAnalysis', type)
};
const handleToUserCenter = () => {
  const url = `/user/my-favorites`;
  window.open(url, "_blank");
};
const handleVip = () => {
  buyVip ( { fromText:`导航栏` })
}


/* ********************** Computed ********************** */
const isActiveRoute = computed ( () => {
  return ( names: string[]) => {
    return names.includes( route.name as string );
  }
});
</script>
<style lang="scss" module src="../header-navigator.scss"></style>
